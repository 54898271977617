/* eslint-disable prefer-destructuring,no-case-declarations,no-unused-vars,import/no-cycle */
import {
    camelCaseToTitleCase,
    capitalize,
    capitalizeFirstLetterWord,
    convertObjectKeys,
    getType,
    roundDown,
    ucFirst,
    validations,
} from "@kateinnovations/javascript-helpers";
import { addressFormat, countryList, getLatLngByAddress } from "helpers";

const buttonAddValuation = {
    id: 1,
    title: "compview.addToValuation",
    onClickHandler: "onClickHandlerAddValuation",
    setValue: "valuationId",
    icon: "fa fa-plus",
    type: "button",
};

const getTotalSurfaceOffBrainbayObject = async ({ data }) => {
    const surface = await data.reduce(
        async (surfaceTotalAsObject, currentItem) => {
            const tempData = await surfaceTotalAsObject;
            const tempCurrentItem = await currentItem;
            const surfaceItem = tempCurrentItem?.kadastraleGegevens?.oppervlakte || {};
            Object.keys(surfaceItem).forEach((key) => {
                const value = surfaceItem[key];
                if (!validations.number(value).error) {
                    const convertedToNumber = JSON.parse(value);
                    tempData[key].value += convertedToNumber;
                }
            });
            return tempData;
        },
        Promise.resolve({
            hectare: {
                label: "infoDataBox.content.surfaceAre.value.hectare.postfix",
                value: 0,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            },
            are: {
                label: "infoDataBox.content.surfaceAre.value.are.postfix",
                value: 0,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            },
            centiare: {
                label: "infoDataBox.content.surfaceAre.value.centiare.postfix",
                value: 0,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            },
            squareMeter: {
                label: "infoDataBox.content.surfaceAre.value.squareMeter.postfix",
                value: 0,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            },
        })
    );

    if (surface.centiare.value > 99) {
        const toAreRound = roundDown(surface.centiare.value / 100);
        surface.are.value += toAreRound;
        surface.centiare.value -= toAreRound * 100;
    }

    if (surface.are.value > 99) {
        const toHectareRound = roundDown(surface.are.value / 100);
        surface.hectare.value += toHectareRound;
        surface.are.value -= toHectareRound * 100;
    }

    surface.squareMeter.value =
        surface.squareMeter.value + surface.centiare.value + surface.are.value * 100 + surface.hectare.value * 10000;

    return surface;
};

const getAddressFromBrainbayObject = ({ data }) => {
    if (validations.isEmpty(data) || getType(data) !== "object") return undefined;
    const countryName = data.land && capitalizeFirstLetterWord(capitalize(data.land))?.trim();
    const country = countryName && countryList.nl.find((item) => item.country === countryName)?.alpha2?.toUpperCase();
    const numberReeksStart = data?.huisnummer?.reeks?.begin || data?.huisnummerReeksBegin || ""; // both occur in the v2 one, first one in v1
    const numberReeksEnd = data?.huisnummer?.reeks?.eind || data?.huisnummerReeksBegin || ""; //  both occur in the v2 one, first one in v1
    let numberReeks = "";
    if (numberReeksStart || numberReeksEnd) {
        numberReeks = `${numberReeksStart || ""} - ${numberReeksEnd || ""}`;
    }
    return {
        street: ucFirst(data.straatnaam)?.trim(),
        number: data.huisnummer?.hoofdnummer || data.huisnummer,
        addition: data?.huisnummertoevoeging ?? data?.huisnummertoevoeg ?? data?.HuisnummertoevoegingDisplay, // todo discuss order
        additionExtra: numberReeks,
        postalCode: data.postcode?.toString()?.replaceAll(/\s+/g, "")?.toUpperCase(),
        city: capitalizeFirstLetterWord(capitalize(data?.woonplaats))?.trim(),
        province: capitalizeFirstLetterWord(capitalize(data?.provincie))?.trim(),
        municipality: capitalizeFirstLetterWord(capitalize(data?.gemeente))?.trim(),
        country,
        countryName,
    };
};

const serializeCompViewData = {
    serializeBrainbayForInfoBoxData: async ({
        dataObject = {},
        addButtonAddValuation = false,
        brainbayType,
        postData,
    }) => {
        if (validations.isEmpty(dataObject)) return undefined;

        const address = getAddressFromBrainbayObject({ data: dataObject.objectDetails?.adres?.nederlands });
        const title = addressFormat({ address, language: "nl" });
        const mainImage = postData.valuation_report_photo_main;

        const content = [];
        let typeTranslated = postData.import_transaction_type;
        if (typeTranslated === "vacant") {
            typeTranslated = "koop";
        } else if (typeTranslated === "rental") {
            typeTranslated = "huur";
        }
        content.push({
            label: "infoDataBox.content.type.label",
            value: typeTranslated,
            type: "string",
        });
        let hoofdfunctieTranslated = postData.valuation_object_type;
        if (hoofdfunctieTranslated === "residential") {
            hoofdfunctieTranslated = "wonen";
        }
        content.push({
            label: "infoDataBox.content.mainFunction.label",
            value: hoofdfunctieTranslated,
            type: "string",
        });

        if (brainbayType === "resi" && postData.valuation_object_type_residential) {
            let objectTypeResiTranslated = postData.valuation_object_type_residential;
            if (objectTypeResiTranslated === "singlefamilydwelling") {
                objectTypeResiTranslated = "eengezinswoning";
            } else if (objectTypeResiTranslated === "multifamilydwelling") {
                objectTypeResiTranslated = "meergezinswoning";
            }
            content.push({
                label: "infoDataBox.content.subFunction.label",
                value: objectTypeResiTranslated,
                valueIsMultiple: false,
                type: "contentObject",
            });
        }
        if (postData.valuation_transaction_status) {
            content.push({
                label: "infoDataBox.content.currentStatus.label",
                value: postData.valuation_transaction_status,
                type: "string",
            });
        }
        if (postData.valuation_marketability_askingprice) {
            content.push({
                label: "infoDataBox.content.askingPrice.label",
                value: postData.valuation_marketability_askingprice,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_marketability_purchaseprice) {
            content.push({
                label: "infoDataBox.content.transactionPrice.label",
                value: postData.valuation_marketability_purchaseprice,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_value_ri_total) {
            content.push({
                label: "infoDataBox.content.rentalPriceYear.label",
                value: postData.valuation_value_ri_total,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.object_transaction_rental_tenant_ri_sqm) {
            content.push({
                label: "infoDataBox.content.rentalPriceYearSqm.label",
                value: postData.object_transaction_rental_tenant_ri_sqm,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_value_ri_month) {
            content.push({
                label: "infoDataBox.content.rentalPriceMonth.label",
                value: postData.valuation_value_ri_month,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.object_transaction_rental_ri_mnth_sqm) {
            content.push({
                label: "infoDataBox.content.rentalPriceMonthSqm.label",
                value: postData.object_transaction_rental_ri_mnth_sqm,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_transactions_rental_conditions) {
            content.push({
                label: "infoDataBox.content.rentalConditions.label",
                value: postData.valuation_transactions_rental_conditions,
                type: "string",
            });
        }
        if (postData.valuation_transactions_rental_price_conditions) {
            content.push({
                label: "infoDataBox.content.rentalConditions.label",
                value: postData.valuation_transactions_rental_price_conditions,
                type: "string",
            });
        }
        if (postData.valuation_date_valuation) {
            content.push({
                label: "compview.transactionModal.transactionDate",
                value: postData.valuation_date_valuation,
                type: "customDateTimeType",
            });
        }

        if (postData.valuation_value_nla_total) {
            content.push({
                label: "infoDataBox.content.surfaceSquareMeter.label",
                value: postData.valuation_value_nla_total,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    postFix: "m²",
                },
            });
        }
        if (
            postData.valuation_value_total_nia &&
            postData.valuation_value_total_nia !== postData.valuation_value_nla_total
        ) {
            content.push({
                label: "infoDataBox.content.niaSquareMeter.label",
                value: postData.valuation_value_total_nia,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    postFix: "m²",
                },
            });
        }
        if (postData.valuation_marketability_purchaseprice_sqm) {
            content.push({
                label: "infoDataBox.content.transactionPriceSqm.label",
                value: postData.valuation_marketability_purchaseprice_sqm,
                type: "customMoneyType",
                format: {
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_landregistry_info_mother_parcels && brainbayType === "agri") {
            content.push({
                label: "infoDataBox.content.numberOfLots.label",
                value: postData.valuation_landregistry_info_mother_parcels,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_site_areaha) {
            content.push({
                label: "infoDataBox.content.surfaceAre.value.hectare.postfix",
                value: postData.valuation_site_areaha,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_site_areaa) {
            content.push({
                label: "infoDataBox.content.surfaceAre.value.are.postfix",
                value: postData.valuation_site_areaa,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_site_areaca) {
            content.push({
                label: "infoDataBox.content.surfaceAre.value.centiare.postfix",
                value: postData.valuation_site_areaca,
                type: "customNumberType",
                format: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            });
        }
        if (postData.valuation_construction_year) {
            content.push({
                label: "infoDataBox.content.constructionYear.label",
                value: postData.valuation_construction_year,
                type: "string",
            });
        }
        if (postData.valuation_construction_period_desc) {
            content.push({
                label: "infoDataBox.content.constructionPeriod.label",
                value: postData.valuation_construction_period_desc,
                type: "string",
            });
        }
        if (postData.valuation_sustainability_energylabel) {
            content.push({
                label: "infoDataBox.content.energyLabel.label",
                value: postData.valuation_sustainability_energylabel.replaceAll("p", "+"),
                type: "string",
            });
        }

        if (postData.transaction_deed_of_transfer_date_signature) {
            content.push({
                label: "infoDataBox.content.dateSignatureDeed.label",
                value: postData.transaction_deed_of_transfer_date_signature,
                type: "customDateTimeType",
            });
        } else if (postData.transaction_subscription_date_start) {
            content.push({
                label: "infoDataBox.content.subscribeDate.label",
                value: postData.transaction_subscription_date_start,
                type: "customDateTimeType",
            });
        }
        if (postData.transaction_deed_of_transfer_date_transfer && brainbayType !== "resi") {
            content.push({
                label: "infoDataBox.content.dateTransport.label",
                value: postData.transaction_deed_of_transfer_date_transfer,
                type: "customDateTimeType",
            });
        }
        if (postData.transaction_subscription_date_end) {
            content.push({
                label: "infoDataBox.content.unsubscribeDate.label",
                value: postData.transaction_subscription_date_end,
                type: "customDateTimeType",
            });
        }

        const footer = [];

        if (addButtonAddValuation) footer.push(buttonAddValuation);

        return {
            id: dataObject.id ?? dataObject.objectGuid,
            image: {
                url: mainImage || undefined,
                title,
            },
            title: {
                text: title,
                additional: postData.source,
            },
            address,
            content,
            footer,
            type: postData.import_transaction_type,
            source: postData.source,
        };
    },
    brainbayAgriculture: async ({ dataObject, addButtonAddValuation, language = "en", googleAPIKey }) => {
        return serializeCompViewData.brainbay({
            dataObject,
            brainbayType: "agri",
            addButtonAddValuation,
            language,
            googleAPIKey,
        });
    },
    brainbayBusiness: async ({ dataObject, addButtonAddValuation, language = "en", googleAPIKey }) => {
        return serializeCompViewData.brainbay({
            dataObject,
            brainbayType: "bog",
            addButtonAddValuation,
            language,
            googleAPIKey,
        });
    },
    brainbayResidential: async ({ dataObject, addButtonAddValuation, language = "en", googleAPIKey }) => {
        return serializeCompViewData.brainbay({
            dataObject,
            brainbayType: "resi",
            addButtonAddValuation,
            language,
            googleAPIKey,
        });
    },
    brainbayAgricultureV2: async ({ dataObject, addButtonAddValuation, language = "en", googleAPIKey }) => {
        return serializeCompViewData.brainbayV2({
            dataObject: dataObject?.result,
            brainbayType: "agri",
            addButtonAddValuation,
            language,
            googleAPIKey,
        });
    },
    brainbayBusinessV2: async ({ dataObject, addButtonAddValuation, language = "en", googleAPIKey }) => {
        return serializeCompViewData.brainbayV2({
            dataObject: dataObject?.result,
            brainbayType: "bog",
            addButtonAddValuation,
            language,
            googleAPIKey,
        });
    },
    brainbayResidentialV2: async ({ dataObject, addButtonAddValuation, language = "en", googleAPIKey }) => {
        return serializeCompViewData.brainbayV2({
            dataObject: dataObject?.result,
            brainbayType: "resi",
            addButtonAddValuation,
            language,
            googleAPIKey,
        });
    },
    brainbay: async ({ dataObject, brainbayType, addButtonAddValuation, language = "en", googleAPIKey }) => {
        if (validations.isEmpty(dataObject) || getType(dataObject) !== "object") return {};
        const currentData =
            dataObject?.objectbeeld?.object ||
            dataObject?.businessObjectbeeld?.object ||
            dataObject?.agrarischObjectbeeld?.object ||
            {};
        const postData = await serializeCompViewData.brainbayTransform({
            dataObject: currentData,
            language,
            brainbayType,
            googleAPIKey,
        });
        const infoBoxData =
            (await serializeCompViewData.serializeBrainbayForInfoBoxData({
                dataObject: currentData,
                addButtonAddValuation,
                brainbayType,
                postData,
            })) || {};
        infoBoxData.postData = postData;
        return infoBoxData;
    },
    brainbayV2: async ({ dataObject, brainbayType, addButtonAddValuation, language = "en", googleAPIKey }) => {
        if (validations.isEmpty(dataObject) || getType(dataObject) !== "object") return {};
        const currentData =
            dataObject?.objectbeeld?.object ||
            dataObject?.businessObjectbeeld?.object ||
            dataObject?.agrarischObjectbeeld?.object ||
            {};
        const postData = await serializeCompViewData.brainbayTransformV2({
            dataObject: currentData,
            language,
            brainbayType,
            googleAPIKey,
        });
        const infoBoxData =
            (await serializeCompViewData.serializeBrainbayForInfoBoxData({
                dataObject: currentData,
                addButtonAddValuation,
                brainbayType,
                postData,
            })) || {};
        infoBoxData.postData = postData;
        return infoBoxData;
    },
    brainbayBulk: async ({ dataObject = {}, categoryId, category, color, language = "en", googleAPIKey }) => {
        if (validations.isEmpty(dataObject) || getType(dataObject) !== "object") return {};
        if (dataObject?.name) {
            // new optimized output format
            return {
                // eslint-disable-next-line no-underscore-dangle
                id: dataObject?._id,
                title: dataObject?.name,
                latLng: { lat: parseFloat(dataObject?.latLon[0]), lng: parseFloat(dataObject?.latLon[1]) },
                type: "transaction",
                subType: category,
                categoryId,
                category,
                color,
                dateCreated: dataObject?.Transactiedatum, // note this is not the date created, just the date we want to show :P
            };
        }
        const dataKeyAsCamelCase = await convertObjectKeys({ dataObject, convertType: "camelCase" });

        const { straatnaam, huisnummer, huisnummertoevoeging, postcode, woonplaats, provincie } =
            dataKeyAsCamelCase || {};

        const addressForGeoLocation = {
            street: ucFirst(straatnaam),
            street_number: huisnummer?.toString(),
            street_number_additions: huisnummertoevoeging?.toString(),
            postal_code: postcode?.toString()?.replaceAll(/\s+/g, "")?.toUpperCase(),
            city: capitalizeFirstLetterWord(capitalize(woonplaats)),
            province: capitalizeFirstLetterWord(capitalize(provincie)),
            country: "Nederland",
        };

        const addressObject = {
            straatnaam,
            huisnummer,
            huisnummertoevoeging,
            postcode,
            woonplaats,
            provincie,
            land: "Nederland",
        };
        let latLng =
            dataObject?.Location?.Lat && dataObject?.Location?.Lon
                ? { lat: parseFloat(dataObject?.Location?.Lat), lng: parseFloat(dataObject?.Location?.Lon) }
                : null;
        if (latLng === null) {
            latLng =
                dataObject?.lat && dataObject?.lng
                    ? { lat: parseFloat(dataObject.lat), lng: parseFloat(dataObject.lng) }
                    : null;
        }
        if (latLng === null) {
            latLng = await getLatLngByAddress({
                address: addressForGeoLocation,
                googleAPIKey,
                prefixStorageKey: "brainbay",
                language,
            });
        }
        const address = getAddressFromBrainbayObject({ data: addressObject });
        const title = addressFormat({ address, language });
        const titleCityFirst = addressFormat({ address, language, cityIsFirstItem: true });
        return {
            id: dataKeyAsCamelCase.objectGuid,
            title,
            titleCityFirst,
            titleForSort: titleCityFirst,
            address,
            latLng,
            type: "transaction",
            subType: category,
            categoryId,
            category,
            color,
            dateCreated: dataObject?.TransactieDatum, // note this is not the date created, just the date we want to show :P
        };
    },
    brainbayTransform: async ({ dataObject = {}, brainbayType, language = "en", googleAPIKey }) => {
        // specifically removed getType(dataObject) !== "object"
        if (validations.isEmpty(dataObject)) return undefined;

        const address = getAddressFromBrainbayObject({ data: dataObject.objectDetails?.adres?.nederlands });
        const addressForLatLng = {
            street: address?.street,
            street_number: address?.number,
            street_number_additions: address?.addition,
            postal_code: address?.postalCode,
            city: address?.city,
            province: address?.province,
            country: address?.countryName,
        };
        const cadastralInformation = dataObject?.kadaster?.kadastraleInformatie || undefined;
        const numberOfLots = validations.isNotEmpty(cadastralInformation, true)
            ? cadastralInformation.length
            : undefined;
        const surface = cadastralInformation
            ? await getTotalSurfaceOffBrainbayObject({ data: cadastralInformation })
            : undefined;
        const geolocation = await getLatLngByAddress({
            address: addressForLatLng,
            googleAPIKey,
            prefixStorageKey: "brainbay",
            language,
        });
        const media = dataObject.media?.medium ?? dataObject.media ?? [];

        const mainImage = media.find((item) => item?.mediumGroep === "BEELD" && item?.isHoofdMedium === true);

        const otherImages = [];
        const documents = [];
        media.forEach((item) => {
            if (item?.mediumGroep === "BEELD" && item?.isHoofdMedium === false) {
                otherImages.push(item.url);
            }
            if (item?.mediumGroep === "DOCUMENT" && item?.mediumMimetype !== "MP4") {
                documents.push(item.url);
            }
        });
        // eslint-disable-next-line one-var
        let brainbaySource,
            hoofdfunctie,
            objectTypeSubResidential,
            objectTypeSubResidentialSub,
            energyLabel,
            energyEPC,
            pricingData,
            deedOfTransferDateSignature,
            deedOfTransferDateTransfer,
            historyData,
            purchasePrice,
            purchaseCondition,
            rentalCondition,
            rentalPrice,
            rentalPriceYear,
            rentalPriceMonth,
            rentalPriceYearSqm,
            rentalPriceMonthSqm,
            rentalPriceConditions,
            rentalSpecifications,
            rentalDateSignature,
            rentalDateTransfer,
            rentalDateTerm,
            aantalHuurders,
            brutoAanvangRendement,
            brutoHuuropbrengstKaleHuur,
            oppervlakteIndustrial,
            vrijeHoogte,
            oppervlakteOffice,
            gebouwNaam,
            locationType,
            leaseholdEndDate,
            rentalVatSelection,
            askingPrice,
            nlaTotal,
            parcelArea,
            nia,
            constructionYear,
            constructionPeriod,
            outsideStorage,
            insideStorage,
            askingPriceSqm,
            purchasePriceSqm,
            cubicMeters,
            conditionInside,
            conditionOutside,
            aantalKamers,
            aantalSlaapkamers;

        const externalReference = `T${dataObject?.tiaraObjectID ?? ""}|M${dataObject?.midasObjectID}|O${
            dataObject?.objectGuid
        }`;
        const dateSubscriptionStart = dataObject?.aanmelddatum?.split("T")[0];
        const dateSubscriptionEnd = dataObject?.afmelddatum?.split("T")[0];

        // eslint-disable-next-line default-case
        switch (brainbayType) {
            case "resi":
                const grondKamer = dataObject?.wonen?.woonlagen?.beganeGrondOfFlat?.aantalKamers ?? 0;
                const grondSlaapKamer = dataObject?.wonen?.woonlagen?.beganeGrondOfFlat?.aantalSlaapkamers ?? 0;
                const verdiepingKamer =
                    dataObject?.wonen?.woonlagen?.verdieping
                        ?.map((item) => item.aantalKamers ?? 0)
                        .reduce((x, y) => x + y, 0) ?? 0;
                const verdiepingSlaapKamer =
                    dataObject?.wonen?.woonlagen?.verdieping
                        ?.map((item) => item.aantalSlaapkamers ?? 0)
                        .reduce((x, y) => x + y, 0) ?? 0;
                const zolderKamer =
                    dataObject?.wonen?.zolder?.map((item) => item.aantalKamers ?? 0).reduce((x, y) => x + y, 0) ?? 0;
                const zolderSlaapKamer =
                    dataObject?.wonen?.zolder?.map((item) => item.aantalSlaapkamers ?? 0).reduce((x, y) => x + y, 0) ??
                    0;
                // check https://kateinnovations.atlassian.net/wiki/spaces/KATESOFTWARE/pages/2808086536 for current docs
                aantalKamers = grondKamer + verdiepingKamer + zolderKamer;
                aantalSlaapkamers = grondSlaapKamer + verdiepingSlaapKamer + zolderSlaapKamer;
                brainbaySource = "brainbayResidential";
                hoofdfunctie = "residential";
                const isWoonhuis = dataObject?.wonen?.woonhuis;
                if (isWoonhuis) {
                    objectTypeSubResidential = "singlefamilydwelling";
                    objectTypeSubResidentialSub = isWoonhuis.typeWoning;
                    if (isWoonhuis.soortWoning && isWoonhuis.soortWoning.includes("meergezinswoning")) {
                        objectTypeSubResidential = "multifamilydwelling";
                    }
                }
                const isApartment = dataObject?.wonen?.appartement;
                if (isApartment) {
                    objectTypeSubResidential = "multifamilydwelling";
                    objectTypeSubResidentialSub = isApartment.soortAppartement;
                }
                energyLabel = dataObject?.wonen?.wonenDetails?.energielabel?.energieklasse;
                pricingData = dataObject.transactiegegevens;
                deedOfTransferDateSignature = pricingData?.datumOndertekeningAkte;
                deedOfTransferDateTransfer = pricingData?.datumTransport;
                rentalDateSignature = pricingData?.datumOndertekeningAkte;
                rentalDateTransfer = pricingData?.datumTransport;
                purchasePrice = pricingData?.transactieprijs;
                historyData = dataObject?.historie?.koop?.koophistorie?.reverse()[0];
                askingPrice = dataObject?.objectDetails?.koop?.koopprijs || historyData?.koopprijs;
                parcelArea = surface?.squareMeter?.value;
                nlaTotal = dataObject?.wonen?.wonenDetails?.matenEnLigging?.gebruiksoppervlakteWoonfunctie;
                nia = nlaTotal;
                askingPriceSqm =
                    !!askingPrice && !!nia ? Math.round((askingPrice / nia + Number.EPSILON) * 100) / 100 : null;
                purchasePriceSqm =
                    !!purchasePrice && !!nia ? Math.round((purchasePrice / nia + Number.EPSILON) * 100) / 100 : null;
                constructionYear = dataObject?.wonen?.wonenDetails?.bouwjaar?.jaarOmschrijving?.[0]?.jaar;
                cubicMeters = dataObject?.wonen?.wonenDetails?.matenEnLigging?.brutoInhoud;
                outsideStorage = dataObject?.wonen?.wonenDetails?.matenEnLigging?.externeBergruimte;
                insideStorage = dataObject?.wonen?.wonenDetails?.matenEnLigging?.interneBergruimte;
                conditionInside = dataObject?.wonen?.wonenDetails?.onderhoud?.binnen?.waardering;
                conditionOutside = dataObject?.wonen?.wonenDetails?.onderhoud?.buiten?.waardering;
                constructionPeriod = dataObject?.wonen?.wonenDetails?.bouwjaar?.periode;
                rentalCondition = pricingData?.transactieConditie || dataObject?.objectDetails?.huur?.huurConditie;
                rentalPrice = pricingData?.transactieprijs || dataObject?.objectDetails?.huur?.huurPrijs;
                const rentalSpecification = dataObject?.objectDetails?.huur?.huurSpecificaties?.huurSpecificatie;
                rentalSpecifications = Array.isArray(rentalSpecification)
                    ? rentalSpecification.join(", ")
                    : rentalSpecification;
                break;
            case "agri":
                brainbaySource = "brainbayAgriculture";
                hoofdfunctie = camelCaseToTitleCase(Object.keys(dataObject?.aenLV?.hoofdfunctie)?.shift());
                pricingData = dataObject.transacties?.transactiegegevens?.reverse()[0];
                deedOfTransferDateSignature = pricingData?.datumOndertekeningAkte;
                deedOfTransferDateTransfer = pricingData?.datumTransport;
                purchasePrice = pricingData?.objectTransactie?.transactieprijs;
                askingPrice = dataObject?.objectDetails?.koop?.koopprijs;
                parcelArea = surface?.squareMeter?.value;
                nlaTotal = surface?.squareMeter?.value;
                askingPriceSqm =
                    !!askingPrice && !!parcelArea
                        ? Math.round((askingPrice / parcelArea + Number.EPSILON) * 100) / 100
                        : null;
                purchasePriceSqm =
                    !!purchasePrice && !!parcelArea
                        ? Math.round((purchasePrice / parcelArea + Number.EPSILON) * 100) / 100
                        : null;
                break;
            case "bog":
                // check https://kateinnovations.atlassian.net/wiki/spaces/KATESOFTWARE/pages/2809888769 for current docs
                brainbaySource = "brainbayBusiness";
                hoofdfunctie = dataObject?.objectDetails?.hoofdfunctie;
                pricingData = dataObject?.transactiegegevens?.reverse()[0];
                deedOfTransferDateSignature = pricingData?.verkocht?.datumOndertekeningAkte;
                deedOfTransferDateTransfer = pricingData?.verkocht?.datumTransport;
                rentalDateSignature = pricingData?.verhuurd?.datumOndertekeningAkte;
                rentalDateTransfer = pricingData?.verhuurd?.datumIngangHuurovereenkomst;
                rentalDateTerm = pricingData?.verhuurd?.duurHuurovereenkomst;
                nlaTotal = Object.entries(dataObject.functies).map((functie) => {
                    return (
                        functie[1].oppervlakte ??
                        Object.entries(functie[1])
                            .map((item) => item[1]?.oppervlakte ?? null)
                            .reduce((x, y) => {
                                return x + y;
                            }, 0)
                    );
                })[0];
                const functies = [
                    "kantoorruimte",
                    "winkelruimte",
                    "bedrijfsruimte",
                    "horeca",
                    "leisure",
                    "belegging",
                    "maatschappelijkVastgoed",
                    "overige",
                ];
                // eslint-disable-next-line no-restricted-syntax
                for (const item of functies) {
                    if (!constructionYear) {
                        constructionYear =
                            dataObject?.functies?.[item]?.gebouwDetails?.bouwjaar?.jaarOmschrijving?.[0]?.jaar;
                    }
                    if (!constructionPeriod) {
                        constructionPeriod = dataObject?.functies?.[item]?.gebouwDetails?.bouwjaar?.periode;
                    }
                    if (!energyLabel) {
                        energyLabel = dataObject?.functies?.[item]?.gebouwDetails?.energielabel?.energieklasse;
                    }
                    if (!energyEPC) {
                        energyEPC = dataObject?.functies?.[item]?.gebouwDetails?.EPC;
                    }
                    if (!conditionInside) {
                        conditionInside = dataObject?.functies?.[item]?.gebouwDetails?.onderhoud?.binnen?.waardering;
                    }
                    if (!conditionOutside) {
                        conditionOutside = dataObject?.functies?.[item]?.gebouwDetails?.onderhoud?.buiten?.waardering;
                    }
                    if (!gebouwNaam) {
                        gebouwNaam = dataObject?.functies?.[item]?.gebouwDetails?.gebouwnaam;
                    }
                    if (!aantalHuurders) {
                        aantalHuurders = dataObject?.functies?.[item]?.bedrijfshal?.aantalHuurders;
                    }
                    if (!brutoAanvangRendement) {
                        brutoAanvangRendement = dataObject?.functies?.[item]?.bedrijfshal?.brutoAanvangRendement;
                    }
                    if (!brutoHuuropbrengstKaleHuur) {
                        brutoHuuropbrengstKaleHuur =
                            dataObject?.functies?.[item]?.bedrijfshal?.brutoHuuropbrengstKaleHuur;
                    }
                    if (!oppervlakteIndustrial) {
                        oppervlakteIndustrial = dataObject?.functies?.[item]?.bedrijfshal?.oppervlakte;
                    }
                    if (!vrijeHoogte) {
                        vrijeHoogte = dataObject?.functies?.[item]?.bedrijfshal?.vrijeHoogte;
                    }
                    if (!oppervlakteOffice) {
                        oppervlakteOffice = dataObject?.functies?.[item]?.kantoorruimte?.oppervlakte;
                    }
                    if (!locationType) {
                        locationType = dataObject?.functies?.[item]?.gebouwDetails?.lokatie?.liggingen?.ligging?.[0];
                    }
                }
                nia = dataObject?.woonobject?.oppervlakte;
                purchasePrice = pricingData?.verkocht?.prijsSpecificatie?.prijs;
                purchaseCondition = pricingData?.verkocht?.koopConditie;
                rentalCondition = pricingData?.verhuurd?.huurConditie || dataObject?.objectDetails?.huur?.huurConditie;
                rentalPrice =
                    pricingData?.verhuurd?.prijsSpecificatie?.prijs ||
                    dataObject?.objectDetails?.huur?.prijsSpecificatie?.prijs;
                historyData = dataObject.historie?.koop?.koophistorie?.reverse()[0];
                askingPrice =
                    dataObject?.objectDetails?.koop?.prijsSpecificatie?.prijs || historyData?.prijsSpecificatie?.prijs;
                askingPriceSqm =
                    !!askingPrice && !!nlaTotal
                        ? Math.round((askingPrice / nlaTotal + Number.EPSILON) * 100) / 100
                        : null;
                purchasePriceSqm =
                    !!purchasePrice && !!nlaTotal
                        ? Math.round((purchasePrice / nlaTotal + Number.EPSILON) * 100) / 100
                        : null;
                parcelArea = dataObject?.objectDetails?.oppervlaktePerceel || surface?.squareMeter?.value;
                rentalVatSelection = pricingData?.verhuurd?.prijsSpecificatie?.btwBelast;
                break;
        }

        if (rentalCondition && rentalCondition.includes("per maand")) {
            rentalPriceConditions = "per maand";
            rentalPriceMonth = rentalPrice;
            if (rentalCondition === "per vierkante meter per maand") {
                rentalPriceMonth = rentalPrice * nlaTotal;
                rentalPriceMonthSqm = rentalPrice;
            }
            rentalPriceYear = rentalPriceMonth
                ? Math.round((rentalPriceMonth * 12 + Number.EPSILON) * 100) / 100
                : null;
        } else if (rentalCondition && rentalCondition.includes("per jaar")) {
            rentalPriceConditions = "per jaar";
            rentalPriceYear = rentalPrice;
            if (rentalCondition === "per vierkante meter per jaar") {
                rentalPriceYear = rentalPrice * nlaTotal;
                rentalPriceYearSqm = rentalPrice;
            }
            rentalPriceMonth = rentalPrice ? Math.round((rentalPriceYear / 12 + Number.EPSILON) * 100) / 100 : null;
        }
        rentalPriceMonthSqm =
            !rentalPriceMonthSqm && rentalPriceMonth && nlaTotal
                ? Math.round((rentalPriceMonth / nlaTotal + Number.EPSILON) * 100) / 100
                : null;
        rentalPriceYearSqm =
            !rentalPriceYearSqm && rentalPriceYear && nlaTotal
                ? Math.round((rentalPriceYear / nlaTotal + Number.EPSILON) * 100) / 100
                : null;

        const energyType = energyLabel ? "energylabel" : null;
        if (energyLabel && energyLabel.includes("+")) {
            energyLabel = energyLabel.replaceAll("+", "p");
        }
        const rentalDate = rentalDateTransfer || rentalDateSignature || null;
        const transactionDate =
            deedOfTransferDateSignature ||
            deedOfTransferDateTransfer ||
            rentalDateSignature ||
            rentalDateTransfer ||
            null;
        const transactionStatus = dataObject?.objectDetails?.statusBeschikbaarheid?.huidigeStatus;
        let transactionType = dataObject?.objectDetails?.huur ? "rental" : "vacant";
        if (
            transactionStatus === "verkocht" ||
            transactionStatus === "verkocht onder voorbehoud" ||
            transactionStatus === "verkocht bij inschrijving"
        ) {
            transactionType = "vacant";
        }
        const landRegistryInfo = [];
        let leaseholdRent = 0;
        cadastralInformation?.forEach((item) => {
            // 50% of results have a size as a number, other 50% have an object with ca,ha,a in it
            const singleSizeSqm = item.kadastraleGegevens?.oppervlakte; // number (or object)
            const singleSizeHectare = item.kadastraleGegevens?.oppervlakte?.hectare || 0; // ha within object
            const singleSizeAre = item.kadastraleGegevens?.oppervlakte?.are || 0; // a within object
            const singleSizeCentiAre = item.kadastraleGegevens?.oppervlakte?.centiare || 0; // ca within object
            const singleSize = singleSizeCentiAre + singleSizeAre * 100 + singleSizeHectare * 10000;
            const singleTenure = item.eigendomsGegevens?.soort;
            const singleTenureLeaseholdRent = item.eigendomsGegevens?.erfpachtPerJaar || 0;

            const singleParcel = {
                municipality: item.kadastraleGegevens?.kadastraleGemeente,
                section: item.kadastraleGegevens?.sectie,
                number: item.kadastraleGegevens?.perceel,
                index: item.kadastraleGegevens?.indexnummer,
                size: singleSize > 0 ? singleSize : singleSizeSqm, // first show result of object, otherwise number
                comments: item.kadastraleGegevens?.omvang,
                tenure: singleTenure,
            };
            landRegistryInfo.push(singleParcel);

            if (singleTenure === "erfpacht") {
                leaseholdRent += singleTenureLeaseholdRent;
            }
            if (!leaseholdEndDate) {
                leaseholdEndDate = item.eigendomsGegevens?.einddatum;
            }
        });

        return {
            /* ******************************************************* */
            /* **** THIS IS FROM THE OLD BRAINBAY VERSION < 2023 ***** */
            /* ******************************************************* */
            // updating this also requires you to update confluence!
            source: brainbaySource,
            external_reference: externalReference,
            import_transaction_type: transactionType,
            original_import_data: dataObject,
            original_import_files: documents,
            // address details
            valuation_address: address?.street,
            valuation_address_city: address?.city,
            valuation_address_housenumber: address?.number,
            valuation_address_housenumber_add: address?.addition,
            valuation_address_housenumber_add_extra: address?.additionExtra,
            valuation_address_zip: address?.postalCode,
            valuation_address_municipality: address?.municipality,
            valuation_address_country: "Nederland",
            valuation_address_lat: geolocation?.lat,
            valuation_address_lon: geolocation?.lng,
            // general details
            valuation_object_type: hoofdfunctie,
            valuation_object_type_residential: objectTypeSubResidential,
            valuation_object_type_residential_sub: objectTypeSubResidentialSub,
            valuation_transaction_status: transactionStatus,
            valuation_object_description: dataObject?.objectDetails?.aanbiedingstekst,
            valuation_transaction_explanation: dataObject?.objectDetails?.aanbiedingstekst,
            valuation_construction_year: constructionYear,
            valuation_construction_period_desc: constructionPeriod,
            valuation_construction_year_desc: constructionPeriod,
            valuation_landregistry_info_mother_parcels: numberOfLots,
            valuation_report_photo_main: mainImage?.url,
            valuation_report_photo_other: otherImages,
            valuation_condition_indoor: conditionInside,
            valuation_condition_outdoor: conditionOutside,
            valuation_condition_state_maintenance: conditionOutside,
            valuation_sustainability_energy: energyType,
            valuation_sustainability_energylabel: energyLabel,
            valuation_sustainability_epc: energyEPC,
            valuation_transaction_source_type_desc: brainbaySource,
            valuation_transaction_confidential: dataObject?.objectDetails?.vertrouwelijk,
            // meters details
            valuation_value_nla_total: nlaTotal,
            object_transaction_rental_tenant_nla: nlaTotal,
            transaction_area_total: nlaTotal,
            valuation_value_total_nia: nia,
            valuation_value_nia_outside_storage: outsideStorage,
            valuation_value_nia_inside_storage: insideStorage,
            valuation_value_cubic_meter: cubicMeters,
            valuation_landregistry_area_total: parcelArea,
            valuation_site_areaha: surface?.hectare?.value,
            valuation_site_areaa: surface?.are?.value,
            valuation_site_areaca: surface?.centiare?.value,
            // price details
            valuation_marketability_purchaseprice: purchasePrice,
            valuation_marketability_askingprice: askingPrice,
            valuation_value_ri_total: rentalPriceYear,
            valuation_value_ri_month: rentalPriceMonth,
            object_transaction_rental_tenant_ri: rentalPriceYear || brutoHuuropbrengstKaleHuur,
            object_transaction_rental_tenant_ri_month: rentalPriceMonth,
            object_transaction_rental_tenant_ri_sqm: rentalPriceYearSqm,
            object_transaction_rental_ri_mnth_sqm: rentalPriceMonthSqm,
            object_transaction_rental_ri_mnth: rentalPriceMonth,
            valuation_transactions_rental_price_conditions: rentalPriceConditions,
            valuation_transactions_rental_conditions: rentalSpecifications,
            valuation_marketability_purchaseprice_sqm: purchasePriceSqm,
            valuation_marketability_askingprice_sqm: askingPriceSqm,
            // date details
            transaction_deed_of_transfer_date_signature: deedOfTransferDateSignature,
            transaction_deed_of_transfer_date_transfer: deedOfTransferDateTransfer,
            valuation_date_transport: deedOfTransferDateTransfer,
            transaction_subscription_date_start: dateSubscriptionStart,
            transaction_subscription_date_end: dateSubscriptionEnd,
            valuation_date_valuation: transactionDate,
            object_transaction_rental_tenant_startdate: rentalDate,
            // extra details
            valuation_transaction_agent_vendor: dataObject?.kantoorgegevens?.naam1,
            valuation_transactions_price_condition: purchaseCondition,
            transaction_construction_type: dataObject?.objectDetails?.bouwvorm,
            valuation_value_nr_tenants: aantalHuurders,
            valuation_value_giy_kk_acorr_ri: brutoAanvangRendement,
            transaction_area_industrial: oppervlakteIndustrial,
            valuation_construction_clear_height: vrijeHoogte,
            transaction_area_office: oppervlakteOffice,
            valuation_projectname: gebouwNaam,
            transaction_location_type: locationType,
            valuation_landregistry_info: landRegistryInfo,
            valuation_leasehold_rent: leaseholdRent,
            valuation_leasehold_enddate: leaseholdEndDate,
            rental_tenant_vat_selection: rentalVatSelection,
            object_transaction_rental_tenant_leaseterm: rentalDateTerm,
            valuation_report_relevant_correspondence: documents,
        };
    },
    brainbayTransformV2: async ({ dataObject = {}, brainbayType, language = "en", googleAPIKey }) => {
        // specifically removed getType(dataObject) !== "object"

        if (validations.isEmpty(dataObject)) return undefined;

        const address = getAddressFromBrainbayObject({ data: dataObject.objectDetails?.adres?.nederlands });
        const addressForLatLng = {
            street: address?.street,
            street_number: address?.number,
            street_number_additions: address?.addition,
            postal_code: address?.postalCode,
            city: address?.city,
            province: address?.province,
            country: address?.countryName,
        };
        const cadastralInformation =
            dataObject?.kadaster?.kadastraleInformatie || // needed for RESI
            dataObject?.kadaster; // unfortunately sometimes needed for BOG
        const numberOfLots = validations.isNotEmpty(cadastralInformation, true)
            ? cadastralInformation.length
            : undefined;
        const surface = cadastralInformation
            ? await getTotalSurfaceOffBrainbayObject({ data: cadastralInformation })
            : undefined;
        const geolocationFromResponse = dataObject?.objectDetails?.lat
            ? { lat: dataObject.objectDetails.lat, lng: dataObject.objectDetails?.lon ?? dataObject.objectDetails?.lng }
            : null;
        const geolocation =
            geolocationFromResponse ??
            (await getLatLngByAddress({
                address: addressForLatLng,
                googleAPIKey,
                prefixStorageKey: "brainbay",
                language,
            }));
        const media = dataObject.media ?? [];

        const mainImage = media.find((item) => item?.mediumgroep === "BEELD" && item?.isHoofdmedium === true);

        const otherImages = [];
        const documents = [];
        media.forEach((item) => {
            if (item?.mediumgroep === "BEELD" && item?.isHoofdmedium === false) {
                otherImages.push(item.url);
            }
            if (item?.mediumGroep === "DOCUMENT" && item?.mediumMimetype !== "MP4") {
                documents.push(item.url);
            }
        });

        // eslint-disable-next-line one-var
        let brainbaySource,
            hoofdfunctie,
            objectTypeSubResidential,
            objectTypeSubResidentialSub,
            energyLabel,
            energyEPC,
            pricingData,
            deedOfTransferDateSignature,
            deedOfTransferDateTransfer,
            historyData,
            purchasePrice,
            purchaserOrTenantName,
            purchaseCondition,
            rentalCondition,
            rentalPrice,
            rentalPriceYear,
            rentalPriceMonth,
            rentalPriceYearSqm,
            rentalPriceMonthSqm,
            rentalPriceConditions,
            rentalSpecifications,
            rentalDateSignature,
            rentalDateTransfer,
            rentalDateTerm,
            aantalHuurders,
            aantalVhe,
            brutoAanvangRendement,
            brutoHuuropbrengstKaleHuur,
            oppervlakteIndustrial,
            vrijeHoogte,
            objectFrontSize,
            objectConstructionFreeSpan,
            locationWealthClass,
            oppervlakteOffice,
            gebouwNaam,
            locationType,
            leaseholdEndDate,
            rentalVatSelection,
            askingPrice,
            nlaTotal,
            parcelArea,
            nia,
            constructionYear,
            constructionYearDesc,
            constructionPeriod,
            outsideStorage,
            insideStorage,
            askingPriceSqm,
            purchasePriceSqm,
            cubicMeters,
            conditionInside,
            conditionOutside,
            aantalKamers,
            aantalSlaapkamers;

        const tiaraObjectId = dataObject?.tiaraObjectId;
        const objectGuid = dataObject?.objectGuid;
        const externalReference = `T${dataObject?.tiaraObjectId ?? ""}|M${dataObject?.midasObjectId}|O${
            dataObject?.objectGuid
        }`;
        const dateSubscriptionStart = dataObject?.aanmelddatum?.split("T")[0];
        const dateSubscriptionEnd = dataObject?.afmelddatum?.split("T")[0];

        // eslint-disable-next-line default-case
        switch (brainbayType) {
            case "resi":
                const grondKamer = dataObject?.wonen?.woonlagen?.beganeGrondOfFlat?.aantalKamers ?? 0;
                const grondSlaapKamer = dataObject?.wonen?.woonlagen?.beganeGrondOfFlat?.aantalSlaapkamers ?? 0;
                const verdiepingKamer =
                    dataObject?.wonen?.woonlagen?.verdieping
                        ?.map((item) => item.aantalKamers ?? 0)
                        .reduce((x, y) => x + y, 0) ?? 0;
                const verdiepingSlaapKamer =
                    dataObject?.wonen?.woonlagen?.verdieping
                        ?.map((item) => item.aantalSlaapkamers ?? 0)
                        .reduce((x, y) => x + y, 0) ?? 0;
                const zolderKamer =
                    dataObject?.wonen?.woonlagen?.zolder
                        ?.map((item) => item.aantalKamers ?? 0)
                        .reduce((x, y) => x + y, 0) ?? 0;
                const zolderSlaapKamer =
                    dataObject?.wonen?.zolder?.map((item) => item.aantalSlaapkamers ?? 0).reduce((x, y) => x + y, 0) ??
                    0;
                const kelderKamer = dataObject?.wonen?.woonlagen?.kelder?.aantalKamers ?? 0;
                const kelderSlaapKamer = dataObject?.wonen?.woonlagen?.kelder?.aantalSlaapkamers ?? 0;
                // check https://kateinnovations.atlassian.net/wiki/spaces/KATESOFTWARE/pages/2808086536 for current docs
                aantalKamers = grondKamer + verdiepingKamer + zolderKamer + kelderKamer;
                aantalSlaapkamers = grondSlaapKamer + verdiepingSlaapKamer + zolderSlaapKamer + kelderSlaapKamer;
                brainbaySource = "brainbayResidential";
                hoofdfunctie = "residential";
                const isWoonhuis = dataObject?.wonen?.woonhuis;
                if (isWoonhuis) {
                    objectTypeSubResidential = "singlefamilydwelling";
                    objectTypeSubResidentialSub = isWoonhuis.typeWoning;
                    if (isWoonhuis.soortWoning && isWoonhuis.soortWoning.includes("meergezinswoning")) {
                        objectTypeSubResidential = "multifamilydwelling";
                    }
                }
                const isApartment = dataObject?.wonen?.appartement;
                if (isApartment) {
                    objectTypeSubResidential = "multifamilydwelling";
                    objectTypeSubResidentialSub = isApartment.soortAppartement;
                }
                energyLabel = dataObject?.wonen?.wonenDetails?.energielabel?.energieklasse;
                pricingData = dataObject.transactiegegevens; // note; bij RESI een object, bij BOG een array
                deedOfTransferDateSignature = pricingData?.datumOndertekeningAkte;
                deedOfTransferDateTransfer = pricingData?.datumTransport;
                rentalDateSignature = pricingData?.datumOndertekeningAkte;
                rentalDateTransfer = pricingData?.datumTransport;
                purchasePrice = pricingData?.transactieprijs;
                purchaserOrTenantName = pricingData?.koperHuurder?.naam;
                historyData = dataObject?.historie?.koop?.reverse()[0]; // checked (want to specifically write this down)
                askingPrice = dataObject?.objectDetails?.koop?.koopprijs || historyData?.koopprijs;
                parcelArea = dataObject?.objectDetails?.oppervlaktePerceel || surface?.squareMeter?.value;
                nlaTotal = dataObject?.wonen?.wonenDetails?.matenEnLigging?.gebruiksoppervlakteWoonfunctie;
                nia = nlaTotal;
                askingPriceSqm =
                    !!askingPrice && !!nia ? Math.round((askingPrice / nia + Number.EPSILON) * 100) / 100 : null;
                purchasePriceSqm =
                    !!purchasePrice && !!nia ? Math.round((purchasePrice / nia + Number.EPSILON) * 100) / 100 : null;
                constructionYear = dataObject?.wonen?.wonenDetails?.bouwjaar?.jaaromschrijving?.[0]?.jaar;
                constructionYearDesc = dataObject?.wonen?.wonenDetails?.bouwjaar?.jaaromschrijving?.[0]?.omschrijving;
                constructionPeriod = dataObject?.wonen?.wonenDetails?.bouwjaar?.periode;
                cubicMeters = dataObject?.wonen?.wonenDetails?.matenEnLigging?.brutoInhoud;
                outsideStorage = dataObject?.wonen?.wonenDetails?.matenEnLigging?.externeBergruimte;
                insideStorage = dataObject?.wonen?.wonenDetails?.matenEnLigging?.interneBergruimte;
                conditionInside = dataObject?.wonen?.wonenDetails?.onderhoud?.binnen?.waardering;
                conditionOutside = dataObject?.wonen?.wonenDetails?.onderhoud?.buiten?.waardering;

                rentalCondition = pricingData?.transactieConditie || dataObject?.objectDetails?.huur?.huurconditie;
                rentalPrice = pricingData?.transactieprijs || dataObject?.objectDetails?.huur?.huurPrijs;
                const rentalSpecification = dataObject?.objectDetails?.huur?.huurspecificaties;
                rentalSpecifications = Array.isArray(rentalSpecification)
                    ? rentalSpecification.join(", ")
                    : rentalSpecification;
                break;
            case "agri":
                brainbaySource = "brainbayAgriculture";
                hoofdfunctie = camelCaseToTitleCase(Object.keys(dataObject?.aenLv?.hoofdfunctie ?? {})[0]);
                pricingData = dataObject.transactiegegevens?.reverse()[0];
                deedOfTransferDateSignature = pricingData?.datumOndertekeningAkte;
                deedOfTransferDateTransfer = pricingData?.datumTransport;
                purchasePrice = pricingData?.objectTransactie?.transactieprijs;
                askingPrice = dataObject?.objectDetails?.koop?.koopprijs;
                parcelArea = surface?.squareMeter?.value;
                nlaTotal = surface?.squareMeter?.value;
                askingPriceSqm =
                    !!askingPrice && !!parcelArea
                        ? Math.round((askingPrice / parcelArea + Number.EPSILON) * 100) / 100
                        : null;
                purchasePriceSqm =
                    !!purchasePrice && !!parcelArea
                        ? Math.round((purchasePrice / parcelArea + Number.EPSILON) * 100) / 100
                        : null;
                break;
            case "bog":
                // check https://kateinnovations.atlassian.net/wiki/spaces/KATESOFTWARE/pages/2809888769 for current docs
                brainbaySource = "brainbayBusiness";
                hoofdfunctie = dataObject?.objectDetails?.hoofdfunctie;
                pricingData = dataObject?.transactiegegevens?.reverse()[0];
                deedOfTransferDateSignature = pricingData?.verkocht?.datumOndertekeningAkte;
                deedOfTransferDateTransfer = pricingData?.verkocht?.datumTransport;
                rentalDateSignature = pricingData?.verhuurd?.datumOndertekeningAkte;
                rentalDateTransfer = pricingData?.verhuurd?.datumIngangHuurovereenkomst;
                rentalDateTerm = pricingData?.verhuurd?.duurHuurovereenkomst;
                if (rentalDateTerm) {
                    rentalDateTerm /= 12;
                }
                purchaserOrTenantName = pricingData?.koperHuurder?.naam;
                nlaTotal = Object.entries(dataObject.functies).map((functie) => {
                    return (
                        functie[1].oppervlakte ??
                        Object.entries(functie[1])
                            .map((item) => item[1]?.oppervlakte ?? null)
                            .reduce((x, y) => {
                                return x + y;
                            }, 0)
                    );
                })[0];
                const functies = [
                    "kantoorruimte",
                    "winkelruimte",
                    "bedrijfsruimte",
                    "horeca",
                    "leisure",
                    "belegging",
                    "maatschappelijkVastgoed",
                    "overige",
                    // missing 'bouwgrond'
                ];
                // eslint-disable-next-line no-restricted-syntax
                for (const item of functies) {
                    if (!aantalHuurders) {
                        aantalHuurders = dataObject?.functies?.[item]?.aantalHuurders;
                    }
                    if (!aantalVhe) {
                        aantalVhe = dataObject?.functies?.[item]?.aantalZelfstandigVerhuurbareEenheden;
                    }
                    if (!brutoAanvangRendement && dataObject?.functies?.[item]?.brutoAanvangRendement) {
                        // notatie is 1.23. Dit zou 123% betekenen. Dus getransformeerd naar 0.0123
                        brutoAanvangRendement = dataObject?.functies?.[item]?.brutoAanvangRendement / 100;
                    }
                    if (!brutoHuuropbrengstKaleHuur) {
                        brutoHuuropbrengstKaleHuur =
                            dataObject?.functies?.[item]?.bedrijfshal?.brutoHuuropbrengstKaleHuur ||
                            dataObject?.functies?.[item]?.brutoHuuropbrengstKaleHuur;
                    }
                    if (!rentalDateTerm && dataObject?.functies?.[item]?.resterendeLooptijdContractenInMaanden) {
                        rentalDateTerm = dataObject?.functies?.[item]?.resterendeLooptijdContractenInMaanden / 12;
                    }
                    if (!oppervlakteIndustrial) {
                        oppervlakteIndustrial = dataObject?.functies?.[item]?.bedrijfshal?.oppervlakte;
                    }
                    if (!vrijeHoogte) {
                        vrijeHoogte = dataObject?.functies?.[item]?.bedrijfshal?.vrijeHoogte;
                    }
                    if (!objectFrontSize) {
                        objectFrontSize =
                            dataObject?.functies?.[item]?.bedrijfshal?.voorzieningen?.frontbreedte ||
                            dataObject?.functies?.[item]?.frontbreedte;
                    }
                    if (!objectConstructionFreeSpan) {
                        objectConstructionFreeSpan = dataObject?.functies?.[item]?.bedrijfshal?.vrijeOverspanning;
                    }
                    if (!locationWealthClass) {
                        locationWealthClass =
                            dataObject?.functies?.[item]?.bedrijfshal?.voorzieningen?.welstandsklasse ||
                            dataObject?.functies?.[item]?.welstandsklasse;
                    }
                    if (!oppervlakteOffice) {
                        oppervlakteOffice = dataObject?.functies?.[item]?.kantoorruimte?.oppervlakte;
                    }
                }
                if (!oppervlakteOffice) {
                    oppervlakteOffice = dataObject?.functies?.kantoorruimte?.oppervlakte;
                }
                const gebouwDetails = dataObject?.gebouwDetails;
                locationType = gebouwDetails?.lokatie?.liggingen?.[0];
                constructionYear = gebouwDetails?.bouwjaar?.jaarOmschrijving?.[0]?.jaar;
                constructionYearDesc = gebouwDetails?.bouwjaar?.jaarOmschrijving?.[0]?.omschrijving;
                constructionPeriod = gebouwDetails?.bouwjaar?.periode;
                energyEPC = gebouwDetails?.epc;
                conditionInside = gebouwDetails?.onderhoud?.binnen?.waardering;
                conditionOutside = gebouwDetails?.onderhoud?.buiten?.waardering;
                energyLabel = gebouwDetails?.energielabel?.energieklasse;
                gebouwNaam = gebouwDetails?.gebouwnaam;

                nia = dataObject?.woonobject?.oppervlakte; // todo check, can not find ANY object with a 'woonobject'
                purchasePrice = pricingData?.verkocht?.prijsspecificatie?.prijs;
                purchaseCondition = pricingData?.verkocht?.koopconditie;
                rentalCondition = pricingData?.verhuurd?.huurconditie || dataObject?.objectDetails?.huur?.huurconditie;
                rentalPrice =
                    pricingData?.verhuurd?.prijsspecificatie?.prijs ||
                    dataObject?.objectDetails?.huur?.prijsspecificatie?.prijs;
                historyData = dataObject.historie?.koop?.koophistorie?.reverse()[0];
                askingPrice =
                    dataObject?.objectDetails?.koop?.prijsspecificatie?.prijs || historyData?.prijsspecificatie?.prijs;
                askingPriceSqm =
                    !!askingPrice && !!nlaTotal
                        ? Math.round((askingPrice / nlaTotal + Number.EPSILON) * 100) / 100
                        : null;
                purchasePriceSqm =
                    !!purchasePrice && !!nlaTotal
                        ? Math.round((purchasePrice / nlaTotal + Number.EPSILON) * 100) / 100
                        : null;
                parcelArea = dataObject?.objectDetails?.oppervlaktePerceel || surface?.squareMeter?.value;
                rentalVatSelection = pricingData?.verhuurd?.prijsspecificatie?.btwBelast;
                break;
        }

        if (rentalCondition && rentalCondition.includes("per maand")) {
            rentalPriceConditions = "per maand";
            rentalPriceMonth = rentalPrice;
            if (rentalCondition === "per vierkante meter per maand") {
                rentalPriceMonth = rentalPrice * nlaTotal;
                rentalPriceMonthSqm = rentalPrice;
            }
            rentalPriceYear = rentalPriceMonth
                ? Math.round((rentalPriceMonth * 12 + Number.EPSILON) * 100) / 100
                : null;
        } else if (rentalCondition && rentalCondition.includes("per jaar")) {
            rentalPriceConditions = "per jaar";
            rentalPriceYear = rentalPrice;
            if (rentalCondition === "per vierkante meter per jaar") {
                rentalPriceYear = rentalPrice * nlaTotal;
                rentalPriceYearSqm = rentalPrice;
            }
            rentalPriceMonth = rentalPrice ? Math.round((rentalPriceYear / 12 + Number.EPSILON) * 100) / 100 : null;
        }
        rentalPriceMonthSqm =
            !rentalPriceMonthSqm && rentalPriceMonth && nlaTotal
                ? Math.round((rentalPriceMonth / nlaTotal + Number.EPSILON) * 100) / 100
                : null;
        rentalPriceYearSqm =
            !rentalPriceYearSqm && rentalPriceYear && nlaTotal
                ? Math.round((rentalPriceYear / nlaTotal + Number.EPSILON) * 100) / 100
                : null;

        const energyType = energyLabel ? "energylabel" : null;
        if (energyLabel && energyLabel.includes("+")) {
            energyLabel = energyLabel.replaceAll("+", "p");
        }
        const rentalDate = rentalDateTransfer || rentalDateSignature || null;
        const transactionDate =
            deedOfTransferDateSignature ||
            deedOfTransferDateTransfer ||
            rentalDateSignature ||
            rentalDateTransfer ||
            null;
        const transactionStatus = dataObject?.objectDetails?.statusBeschikbaarheid?.huidigeStatus;
        let transactionType = dataObject?.objectDetails?.huur ? "rental" : "vacant";
        if (
            transactionStatus === "verkocht" ||
            transactionStatus === "verkocht onder voorbehoud" ||
            transactionStatus === "verkocht bij inschrijving"
        ) {
            transactionType = "vacant";
        }
        const landRegistryInfo = [];
        let leaseholdRent = 0;
        let cadastralInformationSurfaceHectare = 0;
        let cadastralInformationSurfaceAre = 0;
        let cadastralInformationSurfaceCentiare = 0;
        let cadastralInformationSurfaceSqm = 0;
        cadastralInformation?.forEach((item) => {
            // 50% of results have a size as a number, other 50% have an object with ca,ha,a in it
            const singleSizeSqm = item.kadastraleGegevens?.oppervlakte; // number (or object)
            let singleSizeHectare = item.kadastraleGegevens?.oppervlakte?.hectare || 0; // ha within object
            let singleSizeAre = item.kadastraleGegevens?.oppervlakte?.are || 0; // a within object
            let singleSizeCentiAre = item.kadastraleGegevens?.oppervlakte?.centiare || 0; // ca within object

            let calculatedParcelArea = false;
            if (singleSizeHectare === 0 && singleSizeAre === 0 && singleSizeCentiAre === 0) {
                // calculate Ha, A and Ca from sqm
                calculatedParcelArea = true;
                let calculatedRest = singleSizeSqm;
                singleSizeHectare = roundDown(calculatedRest / 10000);
                calculatedRest -= singleSizeHectare * 10000;
                singleSizeAre = roundDown(calculatedRest / 100);
                calculatedRest -= singleSizeAre * 100;
                singleSizeCentiAre = roundDown(calculatedRest);
            }
            cadastralInformationSurfaceHectare += singleSizeHectare;
            cadastralInformationSurfaceAre += singleSizeAre;
            cadastralInformationSurfaceCentiare += singleSizeCentiAre;
            const singleSize = singleSizeCentiAre + singleSizeAre * 100 + singleSizeHectare * 10000;

            const singleTenure = item.eigendomsgegevens?.soort;
            const singleTenureLeaseholdRent = item.eigendomsgegevens?.erfpachtPerJaar || 0;

            const singleSizeFinal = calculatedParcelArea ? singleSizeSqm : singleSize; // if calculated from sqm, show raw sqm, otherwise the calculated size
            cadastralInformationSurfaceSqm += singleSizeFinal;

            const singleParcel = {
                municipality: item.kadastraleGegevens?.kadastraleGemeente,
                section: item.kadastraleGegevens?.sectie,
                number: item.kadastraleGegevens?.perceel,
                index: item.kadastraleGegevens?.indexnummer,
                size: singleSizeFinal,
                comments: item.kadastraleGegevens?.omvang,
                tenure: singleTenure,
            };
            landRegistryInfo.push(singleParcel);

            // if (singleTenure === "erfpacht") {
            // -> tmp turned off, it contains also a value if singleTenure === 'volle eigendom'
            leaseholdRent += singleTenureLeaseholdRent;
            // }
            if (!leaseholdEndDate) {
                leaseholdEndDate = item.eigendomsgegevens?.einddatum; // todo check, can not find any with an einddatum
            }
        });
        // cadastralInformationSurfaceCentiare can be more than 100, in which case both it and cadastralInformationSurfaceAre to be adjusted. Idem for cadastralInformationSurfaceAre cadastralInformationSurfaceHectare
        cadastralInformationSurfaceAre += Math.floor(cadastralInformationSurfaceCentiare / 100);
        cadastralInformationSurfaceCentiare %= 100;
        cadastralInformationSurfaceHectare += Math.floor(cadastralInformationSurfaceAre / 100);
        cadastralInformationSurfaceAre %= 100;

        let confidential = "no";
        if (dataObject?.objectDetails?.vertrouwelijk === true) {
            confidential = "yes";
        }

        return {
            // updating this also requires you to update confluence!
            source: brainbaySource,
            external_reference: externalReference,
            import_transaction_type: transactionType,
            original_import_data: dataObject,
            original_import_files: documents,
            // address details
            valuation_address: address?.street,
            valuation_address_city: address?.city,
            valuation_address_housenumber: address?.number,
            valuation_address_housenumber_add: address?.addition,
            valuation_address_housenumber_add_extra: address?.additionExtra,
            valuation_address_zip: address?.postalCode,
            valuation_address_municipality: address?.municipality,
            valuation_address_country: "Nederland",
            valuation_address_lat: geolocation?.lat,
            valuation_address_lon: geolocation?.lng,
            // general details
            valuation_object_type: hoofdfunctie,
            valuation_object_type_residential: objectTypeSubResidential,
            valuation_object_type_residential_sub: objectTypeSubResidentialSub,
            valuation_transaction_status: transactionStatus,
            valuation_object_description: dataObject?.objectDetails?.aanbiedingstekst,
            valuation_transaction_explanation: dataObject?.objectDetails?.aanbiedingstekst,
            valuation_construction_year: constructionYear,
            valuation_construction_period_desc: constructionPeriod,
            valuation_construction_year_desc: constructionYearDesc || constructionPeriod,
            valuation_landregistry_info_mother_parcels: numberOfLots,
            valuation_report_photo_main: mainImage?.url,
            valuation_report_photo_other: otherImages,
            valuation_condition_indoor: conditionInside,
            valuation_condition_outdoor: conditionOutside,
            valuation_condition_state_maintenance: conditionOutside,
            valuation_sustainability_energy: energyType,
            valuation_sustainability_energylabel: energyLabel,
            valuation_sustainability_epc: energyEPC,
            valuation_transaction_source_type_desc: brainbaySource,
            valuation_transaction_confidential: confidential,
            // meters details
            valuation_value_nla_total: nlaTotal,
            object_transaction_rental_tenant_nla: nlaTotal,
            transaction_area_total: nlaTotal,
            valuation_value_total_nia: nia,
            valuation_value_nia_outside_storage: outsideStorage,
            valuation_value_nia_inside_storage: insideStorage,
            valuation_value_cubic_meter: cubicMeters,
            valuation_landregistry_area_total: parcelArea > 0 ? parcelArea : cadastralInformationSurfaceSqm,
            valuation_site_areaha: cadastralInformationSurfaceHectare,
            valuation_site_areaa: cadastralInformationSurfaceAre,
            valuation_site_areaca: cadastralInformationSurfaceCentiare,
            // price details
            valuation_marketability_purchaseprice: purchasePrice,
            valuation_marketability_askingprice: askingPrice,
            valuation_value_ri_total: rentalPriceYear,
            valuation_value_ri_month: rentalPriceMonth,
            object_transaction_rental_tenant_ri: brutoHuuropbrengstKaleHuur || rentalPriceYear,
            object_transaction_rental_tenant_ri_month: rentalPriceMonth,
            object_transaction_rental_tenant_ri_sqm: rentalPriceYearSqm,
            object_transaction_rental_ri_mnth_sqm: rentalPriceMonthSqm,
            object_transaction_rental_ri_mnth: rentalPriceMonth,
            valuation_transactions_rental_price_conditions: rentalPriceConditions,
            valuation_transactions_rental_conditions: rentalSpecifications,
            valuation_marketability_purchaseprice_sqm: purchasePriceSqm,
            valuation_marketability_askingprice_sqm: askingPriceSqm,
            // date details
            transaction_deed_of_transfer_date_signature: deedOfTransferDateSignature,
            transaction_deed_of_transfer_date_transfer: deedOfTransferDateTransfer,
            valuation_date_transport: deedOfTransferDateTransfer,
            transaction_subscription_date_start: dateSubscriptionStart,
            transaction_subscription_date_end: dateSubscriptionEnd,
            valuation_date_valuation: transactionDate,
            object_transaction_rental_tenant_startdate: rentalDate,
            // extra details
            valuation_transaction_agent_vendor: dataObject?.kantoorgegevens?.naam1,
            transaction_purchaser_name_vastgoeddata: purchaserOrTenantName,
            object_transaction_rental_tenant: purchaserOrTenantName,
            valuation_transactions_price_condition: purchaseCondition,
            transaction_construction_type: dataObject?.objectDetails?.bouwvorm,
            valuation_value_nr_tenants: aantalHuurders,
            valuation_value_giy_kk_acorr_ri: brutoAanvangRendement,
            transaction_area_industrial: oppervlakteIndustrial,
            valuation_construction_clear_height: vrijeHoogte,
            valuation_value_total_vhe: aantalVhe,
            transaction_area_office: oppervlakteOffice,
            valuation_object_frontsize: objectFrontSize,
            valuation_construction_free_span: objectConstructionFreeSpan,
            transaction_location_wealth_class: locationWealthClass,
            valuation_projectname: gebouwNaam,
            transaction_location_type: locationType,
            valuation_landregistry_info: landRegistryInfo,
            valuation_leasehold_rent: leaseholdRent,
            valuation_leasehold_enddate: leaseholdEndDate,
            rental_tenant_vat_selection: rentalVatSelection,
            object_transaction_rental_tenant_leaseterm: rentalDateTerm,
            valuation_report_relevant_correspondence: documents,
            valuation_transaction_brainbay_tiaraobjectid: tiaraObjectId,
            valuation_transaction_brainbay_objectguid: objectGuid,
        };
    },
};

export default serializeCompViewData;
