import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError, externalAuth } from "features/authentication/authenticationSlice";
import {
    apiCall,
    removeStorageItem,
    ucFirst,
    convertToGivenSeparator,
    convertObjectKeys,
} from "@kateinnovations/javascript-helpers";
import applicationErrorMessage from "helpers/applicationErrorMessage";
import defaultConfig from "config/constants/defaultConfig";
import { toastr } from "react-redux-toastr";
import { isV2TokenExpired } from "helpers/v2TokenHandler";
import compViewForm from "./constants/config";
import v2TokenApi from "../v2/v2TokenApi";

const searchParamExternalSource = {
    city: "Woonplaats",
    street: "Straatnaam",
    zoekterm: "zoekterm",
    saleOrRental: "saleOrRental",
    postalCodeRadiusRadius: "postalCodeRadiusRadius",
    postalCodeRadiusPostalCode: "postalCodeRadiusPostalCode",
    brainbayId: "brainbayId",
    companyType: "soortbedrijf",
    province: "Provincienaam",
    status: "huidigeStatus",
    salePrice: "Koopprijs",
    parcelSize: "perceeloppervlakte",
    culturalInstitutionArea: "MaaCultureleInstellingOppervlakte",
    educationalInstitutionArea: "MaaOnderwijsInstellingOppervlakte",
    sportInstitutionArea: "MaaSportInstellingOppervlakte",
    healthcareInstitutionArea: "MaaZorgInstellingOppervlakte",
    religiousInstitutionArea: "MaaReligieuzeInstellingOppervlakte",
    leisureWellnessArea: "LeisureWellnessOppervlakte",
    transactionDate: "transactiedatumvantot",
    isAuction: "isveiling",
    isConfidential: "isvertrouwelijk",
    isIndicationPublic: "isindicatieopenbaar",
    mainPurpose: "hoofdfunctie",
    horecaRegion: "horecaregio",
    companyHallArea: "bedrijfshaloppervlakte",
    officeArea: "kantoorruimteoppervlakte",
    horecaArea: "horecaoppervlakte",
    shopArea: "winkelruimteoppervlakte",
    groundArea: "terreinoppervlakte",
    companyOfficeHallArea: "bedrijfsruimtekantooroppervlakte",
    constructionPeriod: "bouwperiode",
    isNewConstruction: "isnieuwbouw",
    constructionType: "bouwvorm",
    objectType: "objectsoort",
    residentialObjectSubType: "woningtype",
    otherRealEstate: "Overigog",
    grossCapacity: "brutoinhoud",
    roomAmount: "aantalkamers",
    bedroomAmount: "aantalslaapkamers",
    buildingLayersAmount: "woonlaag",
    priceChangedDate: "prijsgewijzigddatum",
    rentalPrice: "Huurprijs",
    livingArea: "woonoppervlakte",
    gardenArea: "tuinoppervlakte",
    mainGardenOrientation: "hoofdtuinpositie",
    energyLabelFromTo: "energieklassevantot",
    particularities: "bijzonderheden",
    isRecreational: "isrecreatiewoning",
    isMonument: "ismonument",
    hasBasement: "Haskelder",
    bathroomFacilities: "badkamervoorzieningen",
    houseFacilities: "woningvoorzieningen",
    garageTypes: "garagesoorten",
    sites: "Liggingen",
    bathroomAmount: "aantalbadkamers",
    garageCapacity: "garagecapaciteit",
    apartmentType: "appartementtype",
    parkingSpotsCovered: "aantalparkeerplaatsenoverdekt",
    parkingSpotsNonCovered: "aantalparkeerplaatsennietoverdekt",
    auctionDate: "veilingdatumtot",
    postalCodeFrom: "postcodevan",
    postalCodeTo: "postcodetot",
    ignoreLatLon: "ignoreLatLon",
    soilType: "soilType",
};

const compViewFormFieldsApi = createAsyncThunk(
    "compView/compViewFormFieldsApi",
    async ({ category, cancel = false }, { dispatch, getState, signal, rejectWithValue }) => {
        const { auth, compView, userProfile, translations, v2 } = getState();
        if (cancel) return signal.aborted();
        const v2Token = isV2TokenExpired(v2) ? (await dispatch(v2TokenApi()))?.payload?.token : v2.token;
        const isBrainbayV2Connection = compView.categories[category]?.isV2Brainbay ?? false;
        if (!v2Token && isBrainbayV2Connection) return rejectWithValue(["AUTHENTICATION_FAILED"]);
        const error = ["AUTHENTICATION_FAILED"];
        const { jwt } = auth || {};

        if (!jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { isExternalSource, type, subType } = compView.categories[category];

        let fieldsFromConfig = compViewForm[category];
        if (
            category === "valuation" &&
            userProfile.entities.company.toLowerCase().trim() === "apollo valuation & research gmbh"
        ) {
            fieldsFromConfig = compViewForm.valuationApollo;
        }

        const fields = await convertObjectKeys({ dataObject: fieldsFromConfig });
        const categoryId = compView.categories[category].id;
        const url = defaultConfig.api.compView[category]?.formFields ?? defaultConfig.api.compView.readModel.formFields;
        const headers = {};
        let body = null;
        const method = "POST";
        const credentials = "omit";
        let getResponse = null;

        if (!isExternalSource) {
            const exactRequired = [];
            if (type) exactRequired.push(`type:${type}`);
            if (subType) exactRequired.push(`subType:${subType}`);

            const subs = await fields?.reduce(async (data, item) => {
                const tempData = await data;
                const { field, settings } = item || {};
                if (settings.enabled) {
                    const itemObject = {
                        field,
                        type: settings.loadExternalDataType,
                    };

                    const title = [item.title];
                    if (settings?.translation_prefix) title.unshift(settings?.translation_prefix);

                    tempData.push(itemObject);
                }

                return tempData;
            }, []);

            body = {
                simpleQuery: {
                    exactRequired,
                },
                subs,
            };

            headers.accept = "application/json";
            headers["content-Type"] = "application/json";
            headers.authorization = `Bearer ${jwt}`;
            getResponse = await apiCall({
                url,
                method,
                headers,
                credentials,
                mode: "cors",
                signal,
                body,
            });
        }

        if (isExternalSource && !isBrainbayV2Connection) {
            // @TODO: [KATESCRUM-35] investigate why apiCall isn't working
            getResponse = await fetch(url);
        } else if (isExternalSource && isBrainbayV2Connection) {
            getResponse = await apiCall({
                url,
                method: "GET",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    authorization: `Bearer ${v2Token}`,
                },
                credentials: "omit",
                mode: "cors",
            });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";
        const messages = translations.entities;

        if ([401, 403].includes(getResponse.status)) {
            if (!isExternalSource) dispatch(setAuthError("authenticationError"));
            if (isExternalSource && !isBrainbayV2Connection) {
                const sourceType = category.startsWith("brainbay") ? "brainbay" : category;
                const errorMessage = ucFirst(
                    messages["compview.externalSourceManager.error.message.sessionExpired"] ||
                        "your session with %category% is expired".replace(/%category%/g, sourceType)
                );
                removeStorageItem(`${sourceType}Token`, "localStorage");
                toastr.error(errorMessage);
                dispatch(
                    externalAuth({
                        [sourceType]: undefined,
                    })
                );
            }
            return rejectWithValue({ error });
        }

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();

            if (!isExternalSource) {
                const { date, number, text } = response || {};
                const formDataFromResponse = {
                    ...date,
                    ...number,
                    ...text,
                };

                const formData = fields?.reduce((data, item, index) => {
                    const tempData = data;
                    const { title, field, displayType, settings, condition } = item || {};
                    const formElementAttributes = formDataFromResponse[field];

                    delete condition.defaultHidden;

                    if (!formElementAttributes.meta.totalCount) return tempData;

                    let searchParamType = item.type;
                    if (item.type === "select") searchParamType = "exact";
                    if (item.type === "date") searchParamType = "range";
                    const searchParamAsArray = [searchParamType];
                    if (settings.required) searchParamAsArray.push("required");
                    if (settings.negative) searchParamAsArray.push("negative");

                    const { min, max, start, end, top } = formElementAttributes || {};
                    const selectOptions =
                        (item.type === "select" &&
                            top?.map((option) => {
                                const { key } = option || {};
                                const translationKey = [settings.translationPrefix, key]?.join("");
                                const defaultMessage = convertToGivenSeparator(key).toLowerCase();
                                const optionData = {
                                    label: ucFirst(messages[translationKey] || defaultMessage),
                                    value: key,
                                };

                                return optionData;
                            })) ||
                        undefined;

                    const formElementData = {
                        id: index + 1,
                        categoryId,
                        title,
                        name: field,
                        value:
                            (min && max && `${min?.toString()};${max?.toString()}`) ||
                            (start && end && `${start.split("T")[0]};${end.split("T")[0]}`) ||
                            undefined,
                        defaultValue:
                            (min && max && `${min?.toString()};${max?.toString()}`) ||
                            (start && end && `${start.split("T")[0]};${end.split("T")[0]}`) ||
                            "",
                        elementType: ["exact", "fuzzy", "query"].includes(item.type)
                            ? `inputfield${ucFirst(settings.loadExternalDataType)}`
                            : item.type,
                        searchParam: searchParamAsArray.join("_"),
                        min,
                        max,
                        start,
                        end,
                        selectOptions,
                        displayType,
                        condition,
                        hidden: !!condition.field,
                        conditionRules: !condition.field,
                        disabled: true,
                    };

                    tempData.push(formElementData);

                    return tempData;
                }, []);
                return formData;
            }

            if (isExternalSource && isBrainbayV2Connection) {
                const formDataNewBrainbay = fields?.reduce((data, item, index) => {
                    const tempData = data;
                    const { title, field, displayType, settings, condition } = item || {};
                    // if (field !== "postalCodeFrom" && field !== "postalCodeTo") debugger;
                    const filterInfo = response.result.find((element) => element.name === field);
                    const min = filterInfo?.min ?? undefined;
                    const max = filterInfo?.max ?? undefined;
                    const start = filterInfo?.minDate ?? undefined;
                    const end = filterInfo?.maxDate ?? undefined;
                    const selectOptions = (filterInfo?.select ?? []).map((selectOption) => {
                        const { label, value } = selectOption;
                        const translationKey = [settings.translationPrefix, label]?.join("");
                        const defaultMessage = `${convertToGivenSeparator(label).toLowerCase()}`;
                        return {
                            label: ucFirst(messages[translationKey] || defaultMessage),
                            value,
                        };
                    });
                    const searchKey = field.split(".").reverse()[0];
                    const searchParam = searchParamExternalSource[searchKey];

                    const formElementData = {
                        id: index + 1,
                        title,
                        name: field,
                        value:
                            (min && max && `${min?.toString()};${max?.toString()}`) ||
                            (start && end && `${start};${end}`) ||
                            undefined,
                        defaultValue:
                            (min && max && `${min?.toString()};${max?.toString()}`) ||
                            (start && end && `${start};${end}`) ||
                            "",
                        displayType,
                        elementType: ["exact", "fuzzy"].includes(item.type)
                            ? `inputfield${ucFirst(settings.loadExternalDataType)}`
                            : item.type,
                        searchParam,
                        min,
                        max,
                        start,
                        end,
                        selectOptions,
                        condition,
                        hidden: !!condition.field,
                        conditionRules: !condition.field,
                        disabled: true,
                    };
                    tempData.push(formElementData);
                    return tempData;
                }, []);

                return formDataNewBrainbay;
            }
            if (isExternalSource && !isBrainbayV2Connection) {
                const formDataExternal = fields?.reduce((data, item, index) => {
                    const tempData = data;
                    const { title, field, displayType, settings, condition } = item || {};
                    delete condition.defaultHidden;
                    const formElementAttributes = response[field];
                    const searchKey = field.split(".").reverse()[0];
                    const searchParam = searchParamExternalSource[searchKey];

                    const { min, max, start, end, top } = formElementAttributes || {};

                    const selectOptions =
                        (item.type === "select" &&
                            top?.map((option) => {
                                const { label, value } = option || {};
                                const translationKey = [settings.translationPrefix, label]?.join("");
                                const defaultMessage = convertToGivenSeparator(label).toLowerCase();
                                const optionData = {
                                    label: ucFirst(messages[translationKey] || defaultMessage),
                                    value,
                                };

                                return optionData;
                            })) ||
                        undefined;

                    const formElementData = {
                        id: index + 1,
                        title,
                        name: field,
                        value:
                            (min && max && `${min?.toString()};${max?.toString()}`) ||
                            (start && end && `${start.split("T")[0]};${end.split("T")[0]}`) ||
                            undefined,
                        defaultValue:
                            (min && max && `${min?.toString()};${max?.toString()}`) ||
                            (start && end && `${start.split("T")[0]};${end.split("T")[0]}`) ||
                            "",
                        displayType,
                        elementType: ["exact", "fuzzy"].includes(item.type)
                            ? `inputfield${ucFirst(settings.loadExternalDataType)}`
                            : item.type,
                        searchParam,
                        min,
                        max,
                        start,
                        end,
                        selectOptions,
                        condition,
                        hidden: !!condition.field,
                        conditionRules: !condition.field,
                        disabled: true,
                    };
                    tempData.push(formElementData);

                    return tempData;
                }, []);

                return formDataExternal;
            }
        }

        if (getResponse.status !== undefined && !isExternalSource) applicationErrorMessage({ jwt, getResponse, url });

        return rejectWithValue(["APPLICATION_ERROR"]);
    }
);

export default compViewFormFieldsApi;
